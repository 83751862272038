import connect from "react-redux/es/connect/connect";
import { Box } from "@mui/material";

import _languages from "./languages.json";
import Pie from "../../../../components/vis/Pie";
import MultiDimensionalRoundedChartSkeleton from "../../../../components/vis/MultiDimensionalRoundedChartSkeleton";

const stateToProps = ({ insights: {sections: { languages: languages } }}) => {
  return {
    isLoading: languages.isLoading,
    isLoadingForALongTime: languages.isLoadingForALongTime,
    data: languages.data,
  };
};

function mapData(data, key) {
  return data.map(d => ({ label: _languages[d[key]?.toLowerCase()] ?? d[key], value: Number(d.percentage) }));
}

function VisLanguages({
                      sx = {},
                      isLoading,
                      isLoadingForALongTime,
                      data,
                      filters,
                    }) {
  const hasData = data?.languages && data.languages.value.length > 0;
  const languageData = data && data.languages && mapData(data.languages.value, 'language');

  return (
    <Box sx={{ bgcolor: "background.paper", ...sx }}>
      {isLoading && (
        <MultiDimensionalRoundedChartSkeleton sx={{ width: "100%", height: "100%" }}
                                              animation="wave"
                                              vertical={true}
                                              title={isLoadingForALongTime ? "Language - working" : "Language"} />
      )}

      {!isLoading && !hasData && (
        <MultiDimensionalRoundedChartSkeleton sx={{ width: "100%", height: "100%" }}
                                              animation={false}
                                              vertical={true}
                                              title="Language - no data" />
      )}

      {!isLoading && hasData && (
        <Box sx={{ width: "100%", height: "100%" }}>
          <Pie title="Language" data={languageData} height={250} width={250} filters={filters} vertical={true} />
        </Box>
      )}
    </Box>
  );
}

export default connect(stateToProps)(VisLanguages);
