const getActiveUsersCount = (state) => state.insights.activeUsersCount;
const getInsightsSections = (state) => state.insights.sections;
const getCountries = (state) => state.insights.sections.countries;
const getFilters = (state) => state.insights.filters;
const getTenantId = (state) => state.clients.organization.data.id;
const getAudiences = (state) => state.insights.audiences;
const getCreateAudience = (state) => state.insights.createAudience;
const getUpdateAudience = (state) => state.insights.updateAudience;
const getDeleteAudience = (state) => state.insights.deleteAudience;
const getIntersectAudiences = (state) => state.insights.intersectAudiences;
const getCheckAudiencesIntersection = (state) => state.insights.checkAudiencesIntersection;
const getCustomInsights = (state) => state.insights.customInsights;
const getCreateCustomInsight = (state) => state.insights.createCustomInsight;
const getUpdateCustomInsight = (state) => state.insights.updateCustomInsight;
const getArchiveCustomInsight = (state) => state.insights.archiveCustomInsight;
const getUnarchiveCustomInsight = (state) => state.insights.unarchiveCustomInsight;
const getCustomInsightsTemplates = (state) => state.insights.customInsightsTemplates;
const getCustomInsightResults = (state) => state.insights.sections.customInsightsResults;

export {
  getInsightsSections,
  getActiveUsersCount,
  getCountries,
  getFilters,
  getTenantId,
  getAudiences,
  getCreateAudience,
  getUpdateAudience,
  getDeleteAudience,
  getIntersectAudiences,
  getCheckAudiencesIntersection,
  getCustomInsights,
  getCreateCustomInsight,
  getUpdateCustomInsight,
  getArchiveCustomInsight,
  getUnarchiveCustomInsight,
  getCustomInsightsTemplates,
  getCustomInsightResults,
};