import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Box, Button, Card, CardContent, Grid, TextField, CircularProgress} from '@mui/material';

const ClientAddForm = ({
                         clientsLoadCreateOrganizations,
                         setIsSubmittingState,
                         isSubmittingState,
                       }) => {
  return (
    <Formik initialValues={{
      name: '',
      email: '',
      submit: null
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string().min(5).max(255).required('Name is required'),
    })}
    onSubmit={(values, {
      resetForm,
      setErrors,
      setStatus,
      setSubmitting
    }) => {
      if( !isSubmittingState ) {
        clientsLoadCreateOrganizations({
          name: values.name,
        });
        setIsSubmittingState(true);
      }
    }}>
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Grid container={true} spacing={6}>
                <Grid item md={12} xs={12}>
                  <TextField error={Boolean(touched.name && errors.name)}
                             fullWidth
                             helperText={touched.name && errors.name}
                             label="Full name"
                             name="name"
                             onBlur={handleBlur}
                             onChange={handleChange}
                             required
                             value={values.name}
                             variant="outlined" />
                </Grid>


                <Grid item md={12} xs={12}>
                  <Box sx={{ width: 1, display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" size="large" type="submit">
                      New Client

                      {isSubmittingState && (
                        <CircularProgress sx={{ ml: 2 }} size="26px" color="secondary" />
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

ClientAddForm.propTypes = {
  className: PropTypes.string,
};

export default ClientAddForm;
