import React, {useEffect, useRef, useState} from 'react';
import connect from 'react-redux/es/connect/connect';

import {Link as RouterLink} from 'react-router-dom';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {
  Box, Button, Card, CardContent, Checkbox, CircularProgress,
  FormControlLabel, FormHelperText, Grid, Link, TextField, Typography,
} from '@mui/material';
import GuestPage from '../../../components/GuestPage';
import Logo from '../../../components/vectors/Logo';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import {useSnackbar} from 'notistack';

import getText from 'AppUtils/language';
import LoadingScreen from 'AppComponents/LoadingScreen';
import {loadLogin, loadValidateTwoFactorChallenge} from "../store/actions";
import {
  loginAuthenticated,
  loginErrors,
  loginIsLoading,
  loginMsg,
  twoFactorErrors,
  twoFactorIsLoading
} from "../store/selectors";
import {appGetStatus} from "../../../store/selectors";
import {getTwoFactorSet, getTwoFactorValidated} from "../../../utils/localStorage";
import IntercomLogo from "../../../components/vectors/IntercomLogo";

const stateToProps = state => ({
  appGetStatus: appGetStatus(state),
  loginIsLoading: loginIsLoading(state),
  loginAuthenticated: loginAuthenticated(state),
  loginMsg: loginMsg(state),
  loginErrors: loginErrors(state),
  twoFactorIsLoading: twoFactorIsLoading(state),
  twoFactorErrors: twoFactorErrors(state),
});

const actionsToProps = dispatch => ({
  loadLogin: data => dispatch(loadLogin(data)),
  loadValidateTwoFactorChallenge: data => dispatch(loadValidateTwoFactorChallenge(data)),
});

const Login = (props) => {
  const isMountedRef = useIsMountedRef();
  const [msg, setMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const intercomRedirectUrl = process.env.REACT_APP_INTERCOM_REDIRECT_URL;
  let twoFactorSet = useRef(getTwoFactorSet());

  useEffect(() => {
    twoFactorSet.current = getTwoFactorSet();
  }, [props.loginAuthenticated]);

  useEffect(() => {
    if (props.loginMsg && !msg) {
      enqueueSnackbar(getText(props.loginMsg), {
        variant: 'error',
        persist: true
      });

      setMsg(props.loginMsg);
    }
  }, [props.loginMsg]);

  useEffect(() => {
    for (const field in props.loginErrors) {
      if (Object.prototype.hasOwnProperty.call(props.loginErrors, field)) {
        loginFormik.setFieldError(field, props.loginErrors[field][0]);
      }
    }
  }, [props.loginErrors]);

  useEffect(() => {
    for (const field in props.twoFactorErrors) {
      if (Object.prototype.hasOwnProperty.call(props.twoFactorErrors, field)) {
        twoFactorChallengeFormik.setFieldError(field, props.twoFactorErrors[field][0]);
      }
    }
  }, [props.twoFactorErrors]);

  const loginFormik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: false,
      submit: null
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
      password: Yup.string().max(255).required('Password is required'),
      remember: Yup.boolean().required('Remember me checkbox is required'),
    }),
    onSubmit: async (values, {setErrors, setStatus, setSubmitting}) => {
      try {
        setIsLoading(true);

        props.loadLogin({
          email: values.email,
          password: values.password,
          remember: values.remember,
        });

        if (isMountedRef.current) {
          setStatus({success: true});
          setSubmitting(false);
        }
      } catch (err) {
        if (isMountedRef.current) {
          setStatus({success: false});
          setErrors({submit: err.message});
          setSubmitting(false);
        }
      }
    },
  });

  const twoFactorChallengeFormik = useFormik({
    initialValues: {
      code: '',
      submit: null,
    },
    validationSchema: Yup.object().shape({
      code: Yup.string().max(255).required('The code is obviously required'),
    }),
    onSubmit: async (values) => {
      props.loadValidateTwoFactorChallenge({
        code: values.code,
      });
    },
  });


  if (props.appGetStatus === 'loading') {
    return (<LoadingScreen/>);
  }

  return (
    <GuestPage title="Login">
      <Box sx={{ width: 1, height: 1, minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ maxWidth:'sm' }}>
          <Box mb={8} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo/>
            </RouterLink>
          </Box>

          <Card variant="outlined">
            <CardContent>
              <Box mb={3}>
                <Typography variant="h2" sx={{ fontWeight: 'regular', color: 'text.primary' }}>
                  Sign in
                </Typography>

                {(!props.loginAuthenticated || (props.loginAuthenticated && !twoFactorSet)) && (
                  <Typography variant="body2" color="textSecondary">
                    Sign in to your Tappa Content Management System.
                  </Typography>
                )}

                {props.loginAuthenticated && twoFactorSet && !getTwoFactorValidated() && (
                  <Typography variant="body2" color="textSecondary">
                    You can get the code from the authenticator app that you've set the two-factor authentication with.
                  </Typography>
                )}
              </Box>

              <Box flexGrow={1} mt={3}>
                {(!props.loginAuthenticated || (props.loginAuthenticated && !twoFactorSet)) && (
                  <form noValidate onSubmit={loginFormik.handleSubmit}>
                    <Grid container={true} spacing={2}>
                      <Grid item md={12} xs={12}>
                        <TextField error={Boolean(loginFormik.touched.email && loginFormik.errors.email)}
                                   fullWidth
                                   autoFocus
                                   helperText={loginFormik.touched.email && loginFormik.errors.email}
                                   label="Email Address"
                                   margin="normal"
                                   name="email"
                                   onBlur={loginFormik.handleBlur}
                                   onChange={loginFormik.handleChange}
                                   type="email"
                                   value={loginFormik.values.email}
                                   variant="outlined" />
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <TextField error={Boolean(loginFormik.touched.password && loginFormik.errors.password)}
                                   fullWidth
                                   helperText={loginFormik.touched.password && loginFormik.errors.password}
                                   label="Password"
                                   margin="normal"
                                   name="password"
                                   onBlur={loginFormik.handleBlur}
                                   onChange={loginFormik.handleChange}
                                   type="password"
                                   value={loginFormik.values.password}
                                   variant="outlined" />

                        {loginFormik.errors.submit && (
                          <Box mt={3}>
                            <FormHelperText error>
                              {loginFormik.errors.submit}
                            </FormHelperText>
                          </Box>
                        )}
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <FormControlLabel control={
                          <Checkbox name="remember" value={loginFormik.values.remember} onChange={loginFormik.handleChange} />
                        } label="Remember this device" />
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                          <Link variant="body1" color="textPrimary" to="/register" component={RouterLink} sx={{ mr: 4 }}>
                            Need an account? You can register here
                          </Link>

                          <Button color="primary" disabled={loginFormik.isSubmitting || props.loginIsLoading} size="large" type="submit" variant="contained">
                            Log In

                            {props.loginIsLoading && (
                              <CircularProgress sx={{ ml: 2 }} size="26px" color="secondary" />
                            )}
                          </Button>
                        </Box>
                      </Grid>

                      <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: 12 }}>
                        <Typography variant="body2" color="textSecondary" sx={{ mr: 4, mb: 2  }}>
                          Or, login with any of the following services:
                        </Typography>

                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Box sx={{ width: 40, height: 40, cursor: 'pointer' }} onClick={() => {
                            console.log('clicked');
                          }}>
                            <a href={intercomRedirectUrl}>
                              <IntercomLogo />
                            </a>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                )}

                {props.loginAuthenticated && twoFactorSet && !getTwoFactorValidated() && (
                  <form noValidate onSubmit={twoFactorChallengeFormik.handleSubmit}>
                    <Grid container={true} spacing={6}>
                      <Grid item md={12} xs={12}>
                        <TextField error={Boolean(twoFactorChallengeFormik.touched.code && twoFactorChallengeFormik.errors.code)}
                                   fullWidth
                                   helperText={twoFactorChallengeFormik.touched.code && twoFactorChallengeFormik.errors.code}
                                   label="Code"
                                   margin="normal"
                                   name="code"
                                   onBlur={twoFactorChallengeFormik.handleBlur}
                                   onChange={twoFactorChallengeFormik.handleChange}
                                   type="password"
                                   value={twoFactorChallengeFormik.values.code}
                                   variant="outlined" />

                        {twoFactorChallengeFormik.errors.submit && (
                          <Box mt={3}>
                            <FormHelperText error>
                              {twoFactorChallengeFormik.errors.submit}
                            </FormHelperText>
                          </Box>
                        )}
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                          <Button color="primary" disabled={twoFactorChallengeFormik.isSubmitting || props.twoFactorIsLoading} size="large" type="submit" variant="contained">
                            Submit

                            {props.twoFactorIsLoading && (
                              <CircularProgress sx={{ ml: 2 }} size="26px" color="secondary" />
                            )}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </GuestPage>
  );
};

export default connect(stateToProps, actionsToProps)(Login);
