import {Box, Breadcrumbs, Fab, Grid, LinearProgress, Link, Typography} from "@mui/material";
import connect from "react-redux/es/connect/connect";
import { Link as RouterLink } from "react-router-dom";
import React, {useEffect, useState} from "react";

import Page from "@/components/Page";

import {
  getAudiences,
  getCustomInsightResults,
  getFilters,
  getTenantId,
  getCountries,
  getActiveUsersCount,
} from "../store/selectors";
import {loadAudiences, loadInsights} from "../store/actions";

import VisActiveTime from "../subcomponents/vis/VisActiveTime";
import Filters from "../subcomponents/filters/Filters";
import VisGenders from "../subcomponents/vis/VisGenders";
import VisAgeGroups from "../subcomponents/vis/VisAgeGroups";
import CustomInsightsDrawer from "../subcomponents/custom-insights/CustomInsightsDrawer";
import {Insights as InsightsIcon} from "@mui/icons-material";
import VisGenericPie from "../subcomponents/vis/VisGenericPie";
import VisTypedTerms from "../subcomponents/vis/VisTypedTerms";
import VisTrendingTypedTerms from "../subcomponents/vis/VisTrendingTypedTerms";
import VisLanguages from "../subcomponents/vis/VisKbLanguages";
import VisCountries from "../subcomponents/vis/VisCountries";

const stateToProps = (state) => ({
  tenantId: getTenantId(state),
  audiences: getAudiences(state),
  filters: getFilters(state),
  customInsightsResults: getCustomInsightResults(state),
  countries: getCountries(state),
  activeUsersCount: getActiveUsersCount(state),
});
const actionToProps = (dispatch) => ({
  loadInsights: payload => dispatch(loadInsights(payload)),
  loadAudiences: payload => dispatch(loadAudiences(payload)),
});

const Insights = ({
  loadInsights,
  loadAudiences,
  activeUsersCount,
  tenantId,
  audiences,
  filters,
  customInsightsResults,
  countries
}) => {
  const [customInsightsDrawerOpen, setCustomInsightsDrawerOpen] = useState(false);
  const [countriesHasData, setCountriesHasData] = useState(false);

  useEffect(() => {
    if (countries.data && countries.data.countries && countries.data.countries.value.length > 0) {
      setCountriesHasData(true);
    }
  }, [countries]);

  useEffect(() => {
    if (tenantId) {
      loadInsights({ dashboard: 'old-dashboard' });
    }
  }, [loadInsights, tenantId]);

  useEffect(() => {
    if( audiences.data.length === 0 && !audiences.isLoading && tenantId) {
      loadAudiences({
        tenantId: tenantId
      });
    }
  }, [tenantId]);

  const customInsightVis = customInsightResult => {
    const visConfig = {
      ...customInsightResult,
      isLoading: customInsightResult.isLoading,
      isLoadingForALongTime: customInsightResult.isLoadingForALongTime,
    };

    return (
      <VisGenericPie visConfig={visConfig} sx={{ width: "100%", height: "100%" }} filters={filters} />
    );
  };

  const breadCrumbs = (
    <Breadcrumbs aria-label="breadcrumb">
      <Link variant="body1" to="#" component={RouterLink}>
        Reporting
      </Link>

      <Link variant="body1" to="#" component={RouterLink}>
        Insights
      </Link>
    </Breadcrumbs>
  );

  return (
    <Page title="Insights" breadCrumbs={breadCrumbs}>
      <Box mb={8}>
        <Filters audiences={audiences} dashboard="old-dashboard" countriesHasData={countriesHasData} />
      </Box>

      {activeUsersCount !== null && activeUsersCount < 2 && (
        <Box sx={{ w: 1, minHeight: '300px', bgcolor: 'background.darkest', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body1">There is not enough data. Please expand your filters or target audience.</Typography>
        </Box>
      )}

        {(activeUsersCount === null || activeUsersCount >= 2) && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <VisActiveTime filters={filters} sx={{ width: "100%", height: "100%" }} />
          </Grid>

          <Grid item xs={4}>
            <VisGenders filters={filters} sx={{ width: "100%", height: "100%" }} />
          </Grid>

          <Grid item xs={4}>
            <VisAgeGroups filters={filters} sx={{ width: "100%", height: "100%" }} />
          </Grid>

          <Grid item xs={4}>
            <VisLanguages filters={filters} sx={{ width: "100%", height: "100%" }} />
          </Grid>

          {countriesHasData && (
            <Grid item xs={12}>
              <VisCountries filters={filters} sx={{ width: "100%", height: "100%" }} />
            </Grid>
          )}

          <Grid item xs={6}>
            <VisTypedTerms filters={filters} sx={{ width: "100%", height: "400px" }} />
          </Grid>

          <Grid item xs={6}>
            <VisTrendingTypedTerms filters={filters} sx={{ width: "100%", height: "400px" }} />
          </Grid>

          {!customInsightsResults.isLoading && customInsightsResults.data && customInsightsResults.data.map(customInsightResult => (
            <Grid item xs={6} xl={4} key={`custom_insight_vis_${customInsightResult.insight_uid}`}>
              {customInsightVis(customInsightResult)}
            </Grid>
          ))}
        </Grid>
      )}

      {customInsightsResults.isLoading && (
        <Box sx={{ mt: 12 }}>
          <LinearProgress sx={{ borderRadius: 5, height: '7px' }} />
        </Box>
      )}

      <CustomInsightsDrawer tenantId={tenantId} isOpen={customInsightsDrawerOpen} onClose={() => setCustomInsightsDrawerOpen(false)} />

      <Fab variant="extended" color="primary" sx={{ position: 'fixed', bottom: 12, right: 12 }} onClick={() => setCustomInsightsDrawerOpen(true)}>
        <InsightsIcon sx={{ mr: 1 }} />
        Custom Insights
      </Fab>
    </Page>
  );
};

export default connect(stateToProps, actionToProps)(Insights);
