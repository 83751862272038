import {Box, Skeleton, Typography, useTheme} from "@mui/material";
import React from "react";

function BiDimensionalRoundedChartSkeleton ({sx, animation = false, title, ...props}) {
  const theme = useTheme();
  return (
    <Box sx={sx}>
      <Box
        borderRadius={1}
        border={1}
        sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", borderColor: theme.palette.background.darkest, p: 4, overflow: "hidden" }}
      >
        {title && <Typography sx={{ fontWeight: "bold", mb: 2, color: 'text.primary' }}>{title}</Typography>}
        {!title && <Skeleton variant="text" animation={animation} sx={{ fontSize: "1rem", width: "50%" }} />}

        <Box sx={{ display: 'flex', width: '100%', mt: 12 }}>
          <Skeleton variant="circular" animation={animation} sx={{ width: "200px", height: "200px" }} />

          <Box sx={{ display: "flex", flexDirection: "column", flex: "1 1 0%", gap: 4 }}>
            <Skeleton variant="text" animation={animation} sx={{ fontSize: "1rem", width: "100%" }} />
            <Skeleton variant="text" animation={animation} sx={{ fontSize: "1rem", width: "100%" }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default BiDimensionalRoundedChartSkeleton;