import * as INSIGHTS from "./types";

const defaultState = {
  section: "insights",

  filters: {
    date_preset: "last_30_days",
    date_from: undefined,
    date_to: undefined,
    os_types: [],
    genders: [],
    countries: [],
    audience_uid: [],
  },

  activeUsersCount: null,

  sections: {
    user: {
      data: {},
      isLoading: false,
      isLoadingForALongTime: false,
    },
    keyboardEngagement: {
      data: {},
      isLoading: false,
      isLoadingForALongTime: false,
    },
    apps: {
      data: {},
      isLoading: false,
      isLoadingForALongTime: false,
    },
    typedTerms: {
      data: [],
      isLoading: false,
      isLoadingForALongTime: false,
    },
    trendingTypedTerms: {
      data: [],
      isLoading: false,
      isLoadingForALongTime: false,
    },
    genders: {
      data: {},
      isLoading: false,
      isLoadingForALongTime: false,
    },
    ageGroups: {
      data: {},
      isLoading: false,
      isLoadingForALongTime: false,
    },
    interests: {
      data: {},
      isLoading: false,
      isLoadingForALongTime: false,
    },
    customInsightsResults: {
      data: [],
      isLoading: false,
      isLoadingForALongTime: false,
    },
    linguistics: {
      data: {},
      isLoading: false,
      isLoadingForALongTime: false,
    },
    languages: {
      data: {},
      isLoading: false,
      isLoadingForALongTime: false,
    },
    countries: {
      data: {},
      isLoading: false,
      isLoadingForALongTime: false,
    },
  },

  audiences: {
    data: [],
    isLoading: false,
    message: '',
    success: null,
  },

  createAudience: {
    isLoading: false,
    message: '',
    errors: [],
    success: null,
  },

  updateAudience: {
    isLoading: false,
    message: '',
    errors: [],
    success: null,
  },

  checkAudiencesIntersection: {
    isLoading: false,
    message: '',
    data: null,
    errors: [],
    success: null,
  },

  intersectAudiences: {
    isLoading: false,
    message: '',
    errors: [],
    success: null,
  },

  deleteAudience: {
    isLoading: false,
    message: '',
    success: null,
  },

  customInsightsTemplates: {
    data: [],
    isLoading: false,
    message: '',
    success: null
  },

  customInsights: {
    data: [],
    isLoading: false,
    message: '',
    success: null,
  },

  createCustomInsight: {
    isLoading: false,
    message: '',
    errors: [],
    success: null,
  },

  updateCustomInsight: {
    isLoading: false,
    message: '',
    errors: [],
    success: null,
  },

  archiveCustomInsight: {
    isLoading: false,
    message: '',
    success: null,
  },

  unarchiveCustomInsight: {
    isLoading: false,
    message: '',
    success: null,
  },
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case INSIGHTS.SET_LOADING: {
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.payload.section]: {
            ...state.sections[action.payload.section],
            isLoading: action.payload.isLoading,
            isLoadingForALongTime: action.payload.isLoadingForALongTime,
          }
        }
      };
    }

    case INSIGHTS.SET_DATA: {
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.payload.section]: {
            ...state.sections[action.payload.section],
            data: action.payload.data,
            downloadData: action.payload.data,
          },
        }
      };
    }

    case INSIGHTS.SET_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    }

    case INSIGHTS.LOAD_AUDIENCES:
      return {
        ...state,
        audiences: {
          ...state.audiences,
          isLoading: true,
        }
      };

    case INSIGHTS.SET_AUDIENCES:
      return {
        ...state,
        audiences: {
          ...state.audiences,
          isLoading: false,
          data: action.payload.data,
          message: action.payload.message,
          success: action.payload.statusCode === 200,
        },
      };

    case INSIGHTS.RESET_AUDIENCES:
      return {
        ...state,
        audiences: defaultState.audiences,
        createAudience: defaultState.createAudience,
        updateAudience: defaultState.updateAudience,
        deleteAudience: defaultState.deleteAudience,
        checkAudiencesIntersection: defaultState.checkAudiencesIntersection,
        intersectAudiences: defaultState.intersectAudiences,
      };

    case INSIGHTS.SET_AUDIENCE_MEMBERS:
      return {
        ...state,
        audiences: {
          ...state.audiences,
          data: state.audiences.data.map(audience => {
            if( audience.uid === action.payload.audienceUid ) {
              return {
                ...audience,
                members: action.payload.data,
              }
            } else {
              return audience;
            }
          })
        },
      };

    case INSIGHTS.LOAD_CREATE_AUDIENCE:
      return {
        ...state,
        createAudience: {
          ...state.createAudience,
          isLoading: true,
        },
      };

    case INSIGHTS.SET_CREATE_AUDIENCE:
      return {
        ...state,
        createAudience: {
          ...state.createAudience,
          isLoading: false,
          errors: action.payload.errors,
          message: action.payload.message,
          success: action.payload.statusCode === 201,
        },
      };

    case INSIGHTS.LOAD_UPDATE_AUDIENCE:
      return {
        ...state,
        updateAudience: {
          ...state.updateAudience,
          isLoading: true,
        },
      };

    case INSIGHTS.SET_UPDATE_AUDIENCE:
      return {
        ...state,
        updateAudience: {
          ...state.updateAudience,
          isLoading: false,
          errors: action.payload.errors,
          message: action.payload.message,
          success: action.payload.statusCode === 200,
        },
        audiences: {
          ...state.audiences,
          data: state.audiences.data.map(audience => {
            if( audience.uid === action.payload.audienceUid ) {
              return {
                ...audience,
                ...action.payload.data,
              }
            } else {
              return audience;
            }
          })
        },
      }

    case INSIGHTS.LOAD_DELETE_AUDIENCE:
      return {
        ...state,
        deleteAudience: {
          ...state.deleteAudience,
          isLoading: true,
        },
      };

    case INSIGHTS.SET_DELETE_AUDIENCE:
      return {
        ...state,
        deleteAudience: {
          ...state.deleteAudience,
          isLoading: false,
          message: action.payload.message,
          success: action.payload.statusCode === 200,
        },
      };

    case INSIGHTS.LOAD_CHECK_AUDIENCES_INTERSECTION:
      return {
        ...state,
        checkAudiencesIntersection: {
          ...state.checkAudiencesIntersection,
          isLoading: true,
        },
      };

    case INSIGHTS.SET_CHECK_AUDIENCES_INTERSECTION:
      return {
        ...state,
        checkAudiencesIntersection: {
          ...state.checkAudiencesIntersection,
          isLoading: false,
          errors: action.payload.errors,
          message: action.payload.message,
          data: action.payload.data,
          success: action.payload.statusCode === 200,
        },
      };

    case INSIGHTS.RESET_CHECK_AUDIENCES_INTERSECTION:
      return {
        ...state,
        checkAudiencesIntersection: {
          ...defaultState.checkAudiencesIntersection,
        },
      };

    case INSIGHTS.LOAD_INTERSECT_AUDIENCES:
      return {
        ...state,
        intersectAudiences: {
          ...state.intersectAudiences,
          isLoading: true,
        },
      };

    case INSIGHTS.SET_INTERSECT_AUDIENCES:
      return {
        ...state,
        intersectAudiences: {
          ...state.intersectAudiences,
          isLoading: false,
          errors: action.payload.errors,
          message: action.payload.message,
          success: action.payload.statusCode === 201,
        },
      };

    case INSIGHTS.LOAD_CUSTOM_INSIGHTS:
      return {
        ...state,
        customInsights: {
          ...state.customInsights,
          isLoading: true,
        },
      };

    case INSIGHTS.SET_CUSTOM_INSIGHTS:
      return {
        ...state,
        customInsights: {
          ...state.customInsights,
          isLoading: false,
          data: action.payload.data,
          message: action.payload.message,
          success: action.payload.statusCode === 200,
        },
      };

    case INSIGHTS.LOAD_CREATE_CUSTOM_INSIGHT:
      return {
        ...state,
        createCustomInsight: {
          ...state.createCustomInsight,
          isLoading: true,
        },
      };

    case INSIGHTS.SET_CREATE_CUSTOM_INSIGHT:
      return {
        ...state,
        createCustomInsight: {
          ...state.createCustomInsight,
          isLoading: false,
          errors: action.payload.errors,
          message: action.payload.message,
          success: action.payload.statusCode === 201,
        },
      };

    case INSIGHTS.LOAD_UPDATE_CUSTOM_INSIGHT:
      return {
        ...state,
        updateCustomInsight: {
          ...state.updateCustomInsight,
          isLoading: true,
        },
      }

    case INSIGHTS.SET_UPDATE_CUSTOM_INSIGHT:
      return {
        ...state,
        updateCustomInsight: {
          ...state.updateCustomInsight,
          isLoading: false,
          errors: action.payload.errors,
          message: action.payload.message,
          success: action.payload.statusCode === 200,
        },
      };

    case INSIGHTS.LOAD_ARCHIVE_CUSTOM_INSIGHT:
      return {
        ...state,
        archiveCustomInsight: {
          ...state.archiveCustomInsight,
          isLoading: true,
        },
      };

    case INSIGHTS.SET_ARCHIVE_CUSTOM_INSIGHT:
      return {
        ...state,
        archiveCustomInsight: {
          ...state.archiveCustomInsight,
          isLoading: false,
          message: action.payload.message,
          success: action.payload.statusCode === 200
        },
      };

    case INSIGHTS.LOAD_UNARCHIVE_CUSTOM_INSIGHT:
      return {
        ...state,
        unarchiveCustomInsight: {
          ...state.unarchiveCustomInsight,
          isLoading: true,
        },
      };

    case INSIGHTS.SET_UNARCHIVE_CUSTOM_INSIGHT:
      return {
        ...state,
        unarchiveCustomInsight: {
          ...state.unarchiveCustomInsight,
          isLoading: false,
          message: action.payload.message,
          success: action.payload.statusCode === 200
        },
      };

    case INSIGHTS.LOAD_CUSTOM_INSIGHTS_TEMPLATES:
      return {
        ...state,
        customInsightsTemplates: {
          ...state.customInsightsTemplates,
          isLoading: true,
        },
      };

    case INSIGHTS.SET_CUSTOM_INSIGHTS_TEMPLATES:
      return {
        ...state,
        customInsightsTemplates: {
          ...state.customInsightsTemplates,
          isLoading: false,
          data: action.payload.data,
          message: action.payload.message,
          success: action.payload.statusCode === 200,
        },
      };

    case INSIGHTS.SET_SELECTED_LEFT_AXIS_ITEMS:
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.payload.dashboard]: {
            ...state.sections[action.payload.dashboard],
            leftAxis: {
              ...state.sections[action.payload.dashboard].leftAxis,
              items: action.payload.items,
            }
          }
        }
      }

    case INSIGHTS.SET_SELECTED_RIGHT_AXIS_ITEMS:
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.payload.dashboard]: {
            ...state.sections[action.payload.dashboard],
            rightAxis: {
              ...state.sections[action.payload.dashboard].rightAxis,
              items: action.payload.items,
            }
          }
        }
      }

    case INSIGHTS.SET_LEFT_AXIS_RENDITION_TYPE:
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.payload.dashboard]: {
            ...state.sections[action.payload.dashboard],
            leftAxis: {
              ...state.sections[action.payload.dashboard].leftAxis,
              renditionType: action.payload.renditionType,
            },
          },
        },
      };

    case INSIGHTS.SET_RIGHT_AXIS_RENDITION_TYPE:
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.payload.dashboard]: {
            ...state.sections[action.payload.dashboard],
            rightAxis: {
              ...state.sections[action.payload.dashboard].rightAxis,
              renditionType: action.payload.renditionType,
            },
          },
        },
      };

    case INSIGHTS.SET_AXIS_USE_COMMON_Y_SCALE:
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.payload.dashboard]: {
            ...state.sections[action.payload.dashboard],
            axisUseCommonYScale: action.payload.axisUseCommonYScale,
          },
        },
      };

    case INSIGHTS.SET_SELECTED_BREAKDOWN_ITEM:
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.payload.dashboard]: {
            ...state.sections[action.payload.dashboard],
            selectedBreakdownItem: action.payload.selectedBreakdownItem,
          },
        },
      };

    case INSIGHTS.SET_ACTIVE_USERS_COUNT:
      return {
        ...state,
        activeUsersCount:  action.payload.activeUsersCount
      }

    default:
      return state;
  }
}
