import React, {useRef, useState} from 'react';
import connect from 'react-redux/es/connect/connect';


import {useSnackbar} from 'notistack';
import {Box, ButtonBase, Menu, MenuItem, Typography} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {loadLogout} from "../../containers/auth/store/actions";


const stateToProps = state => ({
});

const actionsToProps = dispatch => ({
  loadLogout: () => dispatch(loadLogout()),
});

const Account = ({userInfo, ...props}) => {
  const ref = useRef(null);
  const {enqueueSnackbar} = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      props.loadLogout();
    } catch (err) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
        persist: true
      });
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleOpen} ref={ref}>
        <Typography sx={{ display: { xs: 'none', sm: 'block' }, color: 'text.primary', mr: 2 }}>
          {userInfo && userInfo.name}
        </Typography>

        <AccountCircleIcon alt="User" sx={{ color: 'text.secondary' }} />
      </Box>

      <Menu onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            keepMounted
            anchorEl={ref.current}
            open={isOpen}>
        <MenuItem onClick={handleLogout}>
          <Typography>
            Logout
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default connect(stateToProps, actionsToProps)(Account);
